<template>
  <div>
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs defaultActiveKey="0" :tabBarStyle="{
        margin: '0',
      }">
        <a-tab-pane key="0" :tab="stageName"> </a-tab-pane>
      </a-tabs>
    </Pane>

    <div class="container">
      <a-form :form="form" @submit="handleSubmit" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false">
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="当前项目" class="default-highlight">
              <a-input :disabled="true" :placeholder="designCode"></a-input>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="工程名称" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" class="default-highlight">
              <a-input :disabled="true" :placeholder="projectName" />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item :label="key" v-for="(value, key) in fileObj" :key="key" :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }">
              <FileItem @addFile="getFile" :name="key" :msg="selectFile[key]" />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <div v-if="!isView">
              <a-button htmlType="submit" type="primary" v-if="!isView">提交</a-button>
            </div>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import FileItem from "./components/FileItem";

import { fetchDetail } from "@/api/epc";
import { add, edit, fetchDetailByPid } from "@/api/epc/purchase";

import { mapGetters } from "vuex";

import { groupBy, recoveryArr } from "@/utils/groupBy";

export default {
  name: "purchaseMCA",
  components: {
    FileItem,
  },
  data() {
    return {
      stage: "epc_purchase_main_contract_sign", //主合同签订
      isView: false,

      form: this.$form.createForm(this),
      pid: "", // 项目id
      id: "", //当前表单的id
      hid: "",
      projectName: "",
      designCode: "",

      selectedUserList: [],

      fileObj: {
        标前协议: [],
        标后协议: [],
        主合同: [],
        合同评审表: [],
        合同谈判记录: [],
        合同交底书: [],
        其他附件: [],
      },
      selectFile: {}, //选中的文件
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },
  activated() {
    const { query } = this.$route;
    const { pid, id, hid, isView } = query || {};

    if (this.pid === pid) {
      console.log('有缓存，不重置页面')
      return;
    }

    // 重置数据
    this.selectFile = {}
    this.projectName = '';
    this.designCode = '';

    this.pid = pid;
    this.id = id;
    this.hid = hid;
    this.isView = Boolean(isView) || false;

    this.getDetailByPid();
    this.getName();
  },
  methods: {
    getDetailByPid() {
      fetchDetailByPid({ id: this.pid, stage: this.stage })
        .then((res) => {
          if (!res.id) return;
          this.id = res.id;
          this.hid = res.historyId;

          const groupedPeople = groupBy(res.attachmentList, "type");
          this.selectFile = groupedPeople;
        })
        .catch();
    },

    getFile(val) {
      // 根据name，替换选择的文件
      this.selectFile[val.name] = val.fileList;
      console.log(this.selectFile);
    },

    getName() {
      fetchDetail(this.pid).then((res) => {
        this.projectName = res.name;
        this.designCode = res.designCode;
      });
    },

    selectedUser(value) {
      this.selectedUserList = value;
    },

    onSelectContract(value) {
      console.log(value.contractAmount);
      this.form.setFieldsValue({
        contractCode: value.code,
      });
      this.form.setFieldsValue({
        contractPrice: value.contractAmount * 0.0001,
      });
    },

    handleSubmit(e) {
      e.preventDefault();

      // 对每个文件添加type
      for (let key in this.selectFile) {
        this.selectFile[key].forEach((item) => {
          item.type = key;
        });
      }

      var paramList =
        JSON.stringify(this.selectFile) === "{}"
          ? []
          : recoveryArr(this.selectFile);
      console.log(paramList);

      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          if (this.id) {
            edit({
              id: this.id,
              ...values,
              pid: this.pid,
              designCode: this.designCode,
              projectName: this.projectName,
              attachments: paramList,
              type: this.stage,
              historyId: this.hid,
            }).then(() => {
              this.getDetailByPid();
            });
          } else {
            add({
              ...values,
              pid: this.pid,
              designCode: this.designCode,
              projectName: this.projectName,
              attachments: paramList,
              type: this.stage,
              historyId: this.hid,
            }).then(() => {
              this.getDetailByPid();
            });
          }

        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
